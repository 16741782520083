import { useTranslation } from "react-i18next";
import { Panel, BasicTableInfo, ContainerContents, Milestones } from "./GeneralPanel.styled";
import ContainerIcon from "assets/icons/v2/container_icon.svg";
import MilestoneCompleteIcon from "assets/icons/v2/milestone_complete.svg";
import MilestoneNotStartedIcon from "assets/icons/v2/milestone_not_started.svg";
import { formatDate } from "utils/date";
import { withAuthInfo, WithLoggedInAuthInfoProps } from "services/auth";
import { FeatureFlagNames, useFeatureFlags } from "hooks/useFeatureFlags";

type GeneralPanelProps = {
  accessToken: string;
  vendor?: string;
  container?: string;
  lineItems?: string[];
  billOfLadingNumbers?: string[];
  purchaseOrderNumbers?: string[];
  milestones?: { status: { name: string; location?: string }; eta: string; ata: string }[];
} & WithLoggedInAuthInfoProps;

const GeneralPanel = withAuthInfo(
  ({
    accessToken,
    vendor = "",
    container = "",
    lineItems = [],
    billOfLadingNumbers = [],
    purchaseOrderNumbers = [],
    milestones = [],
  }: GeneralPanelProps) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();

    const featureFlags = useFeatureFlags(accessToken);
    const disablePurchaseOrderColumn = featureFlags[FeatureFlagNames.DisablePurchaseOrderColumn];

    return (
      <Panel>
        <BasicTableInfo>
          <thead>
            <tr>
              <th>{t("Vendor")}</th>
              <th>{t("Bills of Lading")}</th>
              {!disablePurchaseOrderColumn && <th>{t("Purchase Orders")}</th>}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{vendor}</td>
              <td>
                <table>
                  <tbody>
                    {billOfLadingNumbers.map((billOfLadingNumber, index) => (
                      <tr key={index}>
                        <td style={{ paddingLeft: "0px" }}>{billOfLadingNumber}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
              {!disablePurchaseOrderColumn && (
                <td>
                  {purchaseOrderNumbers.map((PONumber, index) => (
                    <tr key={index}>
                      <td style={{ paddingLeft: "0px" }}>{PONumber}</td>
                    </tr>
                  ))}
                </td>
              )}
            </tr>
          </tbody>
        </BasicTableInfo>
        <ContainerContents>
          <h3>{t("Container Contents")}</h3>
          <div className="content">
            <div className="container-title">
              <img src={ContainerIcon} alt="" />
              <span>{container}</span>
            </div>
            <ul className="items">
              {lineItems.map((lineItem, index) => (
                <li key={index}>{lineItem}</li>
              ))}
            </ul>
            <div className="footer" />
          </div>
        </ContainerContents>
        <Milestones>
          <h3>{t("Milestones")}</h3>
          <div className="content">
            <div className="row">
              {milestones.map((milestone, index) => {
                const hasATA = !!milestone.ata;
                const futureAta = milestones.slice(index).some((milestone) => milestone.ata);
                const location = milestone.status.location ? ` ${milestone.status.location}` : "";
                return (
                  <div className="column milestone" key={index}>
                    <div className={hasATA || futureAta ? "ata" : "eta"}>
                      <div className="item mileStoneStatus">
                        <img
                          src={
                            hasATA || futureAta ? MilestoneCompleteIcon : MilestoneNotStartedIcon
                          }
                        />
                      </div>
                      {milestone.ata ? (
                        <div className="item">
                          {`ATA: ${formatDate(new Date(milestone.ata), language)}`}
                        </div>
                      ) : milestone.eta && futureAta ? (
                        <div className="item"></div>
                      ) : (
                        <div className="item">{`ETA: ${formatDate(new Date(milestone.eta), language)}`}</div>
                      )}
                      <div className="item">{t(milestone.status.name) + location}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Milestones>
      </Panel>
    );
  },
);

export default GeneralPanel;
